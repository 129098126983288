import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#FBD33B",
      d: "M16.01 10.167c4.54 0 8.574-1.623 11.17-4.141C24.437 2.954 20.451 1 16.01 1S7.59 2.944 4.84 6.016c2.595 2.518 6.63 4.142 11.17 4.142z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#27C0B9",
      d: "M27.199 25.958c-2.596-2.528-6.63-4.161-11.19-4.161S7.407 23.43 4.82 25.957c2.742 3.082 6.737 5.036 11.19 5.036s8.447-1.954 11.189-5.045z"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "#122233",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      d: "M16.01 30.988c8.279 0 14.99-6.711 14.99-14.99S24.29 1.008 16.01 1.008 1.02 7.719 1.02 15.998s6.71 14.99 14.99 14.99"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "#122233",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      d: "M12.217 30.38c-2.362-3.674-3.83-8.758-3.83-14.377s1.458-10.674 3.8-14.339M19.852 1.625c2.362 3.675 3.83 8.759 3.83 14.378s-1.458 10.674-3.801 14.339M16.008 1.008v29.98M1 16h29.98"
    }, null, -1)
  ])))
}
export default { render: render }