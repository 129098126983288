import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 501.7 453.1"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m0 320 420.3-157.9L359.6.4 0 135.4zM440.8 216.5 0 382v71.1h302.1l199.4-74.9z" }, null, -1)
  ])))
}
export default { render: render }