import { i18nPages } from '@/i18n.pages'

export default function () {
  const legalPages: WebsitePageI18nKey[] = [
    'cookie-policy/index',
    'privacy-policy/index',
    'terms-and-conditions/index',
    'trust-center/index',
  ]

  const allLegalPagesPaths = legalPages
    .map((page) => {
      return i18nPages[page]
    })
    .flatMap(Object.values)

  const route = useRoute()

  const isViewingLegalPage = computed(() =>
    allLegalPagesPaths.some((path) => route.path.includes(path)),
  )

  return isViewingLegalPage
}
