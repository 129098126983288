import * as Fathom from 'fathom-client'

import consts from '@/consts'
export default function () {
  onMounted(() => {
    Fathom.load(consts.fathomTrackingCodeForDotCom, {
      canonical: false,
    })
  })

  const route = useRoute()

  watch(
    () => route.fullPath,
    () => {
      Fathom.trackPageview()
    },
  )
}
