import consts from '@/consts'
export default function () {
  const route = useRoute()

  /**
   * We load the HubSpot tracking code via GTM.
   */

  watch(
    () => route.fullPath,
    () => {
      if ('_hsq' in window && window._hsq !== undefined) {
        window._hsq.push(['setPortalId', consts.hubspotPartnerID])
        window._hsq.push(['trackPageView'])
      }
    },
  )
}
